<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view />
    </transition>
    <Loading v-if="LOADING" />
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "APP",
  components: {
    Loading,
  },
  data() {
    return {
      transitionName: "fadeInDown",
    };
  },
  computed: {
    ...mapState(["LOADING"]),
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "fadeInDown";
      } else {
        this.transitionName = "fadeInDown";
      }
    },
  },
  created() {
    /**
     * 监听页面刷新，存入缓存，再取出来赋值，在app.js的created内执行
  上面是在网上找到的最佳答案，不过实测在微信微信浏览器中，IOS手机不执行，安卓手机没问题。
  解决办法：修改beforeunload，改用unload就可以了
  persistedstate 也可以实现
     */
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store")))
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里 注：(原beforeunload 改 unload)
    window.addEventListener("unload", () => {
      sessionStorage.setItem("store", "");
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>
