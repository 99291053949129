import axios from 'axios'
import { Message } from 'element-ui'

// 创建axios对象
let service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 12000 // 请求的超时
})

const IsPC = function () {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ];
  let flagPc = true;
  for (let i = 0; i < Agents.length; i++) {
    if (userAgentInfo.indexOf(Agents[i]) > 0) {
      flagPc = false;
      break;
    }
  }
  return flagPc;
}

// 添加请求拦截器,
service.interceptors.request.use(
  request => {
    let token = window.localStorage.getItem('token');
    let tid = window.localStorage.getItem('domainTid');
    if (tid) {
      tid = tid.replace(/^"(.*)"$/, '$1')
    }
    if (token) {
      // 设置请求的头信息
      request.headers = {
        'token': token, // 请求添加的token
        'tid': tid,
        'Authorization': `${token}`,
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      }
    }else {
      request.headers = {
        'tid': tid,
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      }
    }

    return request
  },
  error => {
    Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    // 600：登录过期,请重新登录  601: 您的帐号在其他地方已登录,被强制踢出 602: Token验证失败
    if (response.data.code === 600 || response.data.code === 601 || response.data.code === 602) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("tenant_id");
      Message({
        message: response.data.msg || '请求出错，请重试',
        type: 'error',
        duration: 1500,
        onClose: () => {
          return window.location.href = process.env.VUE_APP_SAAS_URL + '/login?redirect=1'
        }
      })
    } else {
      return response.data || response.parmas
    }
    // if(response.data.code == 600 || response.data.code == 601 || response.data.code == 602){
    //     return window.location.href = process.env.VUE_APP_SAAS_URL
    // }else {
    //     return response.data || response.parmas
    // }
  },
  error => {
    Promise.reject(error)
  }
)
export default service
