import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex);

const state = {
  user:[],
  cartcount: 0,
  headtitle:"",
  footactive:"",
  footclass:"",
  LOADING: false,
  headactive:"",
  sideactive:"",
  color: ""
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})