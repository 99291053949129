import Vue from 'vue'
import ElementUI from 'element-ui';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'element-ui/lib/theme-chalk/index.css';
// import "element-ui/lib/theme-chalk/display.css";
import "element-ui/lib/theme-chalk/base.css";
import "./assets/scss/reset.scss";
import "./assets/theme/index.css";
import "./assets/scss/base.scss";
import './assets/scss/userContent.scss'
import "./assets/iconfont/iconfont.css";
import "./assets/iconfont/iconfont.js";
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { getTenantById,getTenantInfo } from '../src/api/template'
// 检测用户操作时间长久
import Automatic from '@/util/automatic'
Vue.use(Automatic)

import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
Vue.component(CollapseTransition.name, CollapseTransition)

import App from './App.vue'
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(VueAxios, axios)

import base from "@/util/base";
Vue.use(base);

import Vue2OrgTree from 'vue2-org-tree';
Vue.use(Vue2OrgTree)


// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  getTenantById(document.domain).then(res =>{
    if(res.code == 200){
      window.localStorage.setItem("domainTid",res.data.tenantId)
      window.localStorage.setItem("loginCode",res.data.loginCode)
      window.localStorage.setItem("Tid",res.data.tid)
      document.title = res.data.loginTitle?res.data.loginTitle:'松冬智慧体育服务平台'
      console.log(window.localStorage.getItem("domainTid"),'domainTid')
    }else {
      this.$message(res.msg);
    }
  }).catch(() =>{})
  if (to.matched.some(res => res.meta.requireAuth)) {// 判断是否需要登录权限
    if (window.localStorage.getItem('token')) {// 判断是否登录
      next()
    } else {// 没登录则跳转到登录界面
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    // if (to.matched.length === 0) {
    //   next('/empty-pages') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
    // }
    next()
  }
})
router.afterEach((to, from) => {
  //将滚动条恢复到最顶端
  window.scrollTo(0, 0);
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
