
// 引入路由和storage工具函数
import storage from '@/util/storage'
import router from "@/router"
import store from '@/store'

let lastTime = new Date().getTime()
let currentTime = new Date().getTime()
let timeOut = 30 * 60 * 1000  //设置超时时间: 30分钟

window.onload = function () {
  window.document.onmousedown = function () {
    storage.setItem("lastTime", new Date().getTime())
  }
};

function checkTimeout() {
  currentTime = new Date().getTime()		//更新当前时间
  lastTime = storage.getItem("lastTime");

  if (currentTime - lastTime > timeOut) { //判断是否超时
    // 清除storage的数据(登陆信息和token)
    storage.clear()
    window.localStorage.clear();
    window.sessionStorage.clear();
    store.dispatch("saveUserInfo", {});
    // window.location.href = `${process.env.VUE_APP_SAAS_URL}`
    
    // 跳到登陆页
    // if(router.currentRouter.path == 'login') return // 当前已经是登陆页时不做跳转
    // router.push({ name: 'login' })
  }
}

export default function () {
  /* 定时器 间隔30秒检测是否长时间未操作页面 */
  window.setInterval(checkTimeout, 30000);
}
