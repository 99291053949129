/**
 * Vuex-mutations
 */
export default {
  setSaveUser(state, user) {
    state.user = user
  },
  setSaveCartCount(state, count) {
    state.count = count
  },
  setSaveHeadTitle(state, headtitle) {
    state.headtitle = headtitle
  },
  setSaveFootActive(state, footactive) {
    state.footactive = footactive
  },
  setSaveLoading(state, LOADING) {
    state.LOADING = LOADING
  },
  showLoading(state) {
    state.LOADING = true
  },
  hideLoading(state) {
    state.LOADING = false
  },
  setSaveHeadActive(state, headactive) {
    state.headactive = headactive
  },
  setSaveSideActive(state, sideactive) {
    state.sideactive = sideactive
  },
  setSaveColor(state, color) {
    state.color = color
  },
}