/**
 * Vuex-actions
 */
export default {
  saveUser(context, user){
    context.commit('setSaveUser', user)
  },
  saveCartCount(context, count){
    context.commit('setSaveCartCount', count)
  },
  saveHeadTitle(context, headtitle){
    context.commit('setSaveHeadTitle', headtitle)
  },
  saveFootActive(context, footactive){
    context.commit('setSaveFootActive', footactive)
  },
  saveLoading(context, LOADING){
    context.commit('setSaveLoading', LOADING)
  },
  saveHeadActive(context, headactive){
    context.commit('setSaveHeadActive', headactive)
  },
  saveSideActive(context, sideActive){
    context.commit('setSaveSideActive', sideActive)
  },
  saveColor(context, color){
    context.commit('setSaveColor', color)
  },
}